import {
  Switch as HeadlessSwitch,
  type SwitchProps as HeadlessSwitchProps,
} from "@headlessui/react";
import { useId } from "react";
import { RiCheckLine } from "react-icons/ri";

import { cn } from "~/utils/classnames";

interface SwitchProps extends HeadlessSwitchProps {
  label?: string;
}

export const Switch = ({ id, label, className, ...props }: SwitchProps) => {
  const genId = useId();

  return (
    <HeadlessSwitch
      id={id || genId}
      className={cn(
        "group relative inline-flex h-7 w-[52px] flex-shrink-0 cursor-pointer rounded-full border-transparent bg-grey-200 p-1 transition-colors duration-200 ease-in-out disabled:opacity-60 data-[checked]:bg-success-600",
        className
      )}
      {...props}
    >
      <RiCheckLine
        className="absolute left-2 top-1/2 size-4 -translate-y-1/2 text-white opacity-0 group-data-[checked]:opacity-100"
        aria-hidden="true"
      />
      <span
        aria-hidden="true"
        className="pointer-events-none absolute top-1/2 inline-block h-5 w-5 -translate-y-1/2 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-6"
      />
    </HeadlessSwitch>
  );
};
